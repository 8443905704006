@import url('https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --body-background: #fafafa;
  --nav-background: white;
  --box-shadow: rgba(0, 0, 0, .1) 0 0 10px;
  --menu-background: white;
  --input-background: #e4e4e4;
  --input-border: var(--logo);
  --errorRed: #d93025;
  --logo: #80a7fc;
  --gray: #71767b;
  --text: black;
}
.darkTheme {
  --body-background: #0e0e10;
  --nav-background: #18181b;
  --box-shadow: rgba(0, 0, 0, .75) 0 0 10px;
  --menu-background: #1f1f23;
  --input-background: #4b4b4e;
  --text: white;
}
.toggleContainer {
  padding: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggleContainer label {
  margin: 0 .5em;
  cursor: pointer;
}
.slider {
  width: 2.5em;
  height: 1.4em;
  position: relative;
  border-radius: 2em;
  border: 1px solid var(--gray);
  cursor: pointer;
}
.slider::before {
  content: "";
  position: absolute;
  background: var(--text);
  border-radius: 50%;
  height: 1em;
  width: 1em;
  top: 2px;
  left: 2px;
  transition: 300ms;
}
#toggle {
  display: none;
}
#toggle:checked + .slider::before {
  transform: translateX(1.1em);
}
.popup {
  overflow: hidden;
}
body {
  color: var(--text);
  text-align: center;
  font-family: "Inter";
  background: var(--body-background);
}
h1 {
  padding: .5em;
  font-size: 2em;
}
main {
  display: flex;
  padding: 1em 0;
  margin-top: 5.5em;
  position: relative;
  align-items: center;
  justify-content: center;
}
form p {
  font-size: 13px;
}
.center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: flex-start;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
}
.center form {
  width: 425px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--nav-background);
  border-radius: .5em;
  margin-bottom: auto;
  margin-top: auto;
  padding: 1.5em;
}
.closeContainer {
  top: 1em;
  right: 1em;
  position: absolute;
}
.close {
  padding: 1.25em;
  position: relative;
  border-radius: .4em;
  background: transparent;
}
.close:hover {
  background: var(--input-background);
}
.close::before, .close::after {
  content: "";
  position: absolute;
  top: 1.2em;
  left: .65em;
  width: 15px;
  height: 2px;
  background: var(--text);
}
.close::before {
  transform: rotate(45deg);
}
.close::after {
  transform: rotate(-45deg);
}
label[for=nickname], label[for=username], label[for=password] {
  font-size: 1em;
  margin-top: 1.5em;
  margin-bottom: .3em;
}
input[type=text], input[type=password] {
  width: 100%;
  height: 2em;
  border: none;
  padding: .5em;
  font-size: 20px;
  color: var(--text);
  border-radius: .25em;
  background: var(--input-background);
}
input[type=text]:focus, input[type=password]:focus {
  outline: none;
  background: var(--body-background);
  border: 2px solid var(--input-border);
}
.red {
  --input-border: var(--errorRed);
}
#password {
  padding-right: 3.5em;
}
.passwordContainer {
  width: 100%;
  position: relative;
}
.passwordToggle {
  top: .1em;
  right: .1em;
  font-size: 1em;
  color: var(--text);
  position: absolute;
  padding: .5em .7em;
  background: transparent;
}
input[type=submit] {
  border: none;
  font-size: 1em;
  color: white;
  margin: 1.25em 0;
  padding: 10px 13px;
  border-radius: .3em;
  font-family: "Inter";
  background: var(--logo);
  cursor: pointer;
}
input[type=submit]:hover {
  background: #5e91ff;
}
input[type=submit]:active {
  background: #407cff;
}
.link {
  color: var(--logo);
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.underline {
  text-decoration: none;
}
img {
  max-width: 90vw;
}
.loginError {
  padding: 1em;
  color: var(--errorRed);
  border: 1px solid var(--errorRed);
  background: rgba(217, 49, 37, 0.1);
}
.signupError {
  width: 100%;
  padding: 0 .5em;
  padding-top: .5em;
  text-align: start;
  color: var(--errorRed);
}
.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
nav ul {
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 1em;
  display: flex;
  position: fixed;
  align-items: center;
  box-shadow: var(--box-shadow);
  justify-content: space-between;
  background: var(--nav-background);
}
li {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}
.navLogo {
  font-size: 3em;
  color: var(--logo);
  position: relative;
  font-weight: bolder;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px var(--logo);
  font-family: "Pathway Gothic One", sans-serif;
  cursor: pointer;
}
.navLogo::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -17px;
  background: var(--logo);
  transition: 500ms;
}
.navLogo:hover::before {
  width: 100%;
}
.navButton {
  font-size: 1em;
  margin-right: .5em;
  color: var(--logo);
  padding: 1em 1.5em;
  border-radius: 2em;
  background: transparent;
  border: 1px solid var(--input-background);
}
.navButton:hover {
  background: var(--input-background);
}
.navIcon {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: var(--logo);
}
.icon {
  font-size: 1.25em;
  margin-right: .5em;
}
button {
  border: none;
  display: flex;
  align-items: center;
  font-family: "Inter";
  justify-content: center;
  cursor: pointer;
}
.menu {
  top: 6.5em;
  right: 1em;
  opacity: 0;
  z-index: 4;
  padding: .5em;
  position: fixed;
  min-width: 200px;
  border-radius: .4em;
  pointer-events: none;
  box-shadow: var(--box-shadow);
  background: var(--menu-background);
}
.menu.active {
  opacity: 1;
  pointer-events: auto;
}
.menu button {
  width: 100%;
  padding: .5em;
  font-size: 1em;
  color: var(--text);
  border-radius: .4em;
  background: transparent;
}
.menu button:hover {
  background: var(--input-background);
}
.back {
  display: flex;
  margin: .25em;
  padding: .2em;
  font-size: 2em;
  color: var(--text);
  border-radius: 50%;
  cursor: pointer;
}
.create {
  margin: auto;
  color: var(--text);
  margin-bottom: 1em;
  border-radius: 2em;
  padding: 1em 1.5em;
  background: transparent;
  border: 1px solid var(--gray);
}
.margin {
  margin: 1em 0;
}
.width {
  width: 550px;
}
.box {
  width: 100%;
  position: relative;
  margin-bottom: -1px;
  border: 1px solid var(--gray);
}
@media (max-width: 550px) {
  .box {
    border-left: none;
    border-right: none;
  }
  main {
    padding: 0;
  }
  .navButton {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
}
.box p {
  font-size: 1em;
  color: var(--text);
}
form.box {
  padding: 1em;
  background: var(--nav-background);
}
.box input {
  margin: 0;
}
textarea {
  width: 100%;
  height: 5em;
  resize: none;
  padding: .5em;
  font-size: 1em;
  margin: .75em 0;
  color: var(--text);
  font-family: "Inter";
  border: 2px solid transparent;
  background: var(--input-background);
}
textarea:focus {
  outline: none;
  border: 2px solid var(--input-border);
}
.name {
  top: 0;
  left: 0;
  margin: 1em;
  display: flex;
  position: absolute;
  align-items: center;
}
.name p:first-child:hover {
  text-decoration: underline;
}
.name p:nth-child(2), .name p:nth-child(3) {
  margin-left: .3em;
  color: var(--gray);
}
.dots {
  top: 0;
  right: 0;
  margin: .3em;
  padding: .25em;
  font-size: 2em;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}
.dotsContainer {
  z-index: 2;
  top: 2.5em;
  right: .5em;
  width: 100px;
  padding: .5em;
  position: absolute;
  border-radius: .4em;
  box-shadow: var(--box-shadow);
  background: var(--menu-background);
}
.dotsContainer button {
  width: 100%;
  padding: .5em;
  border-radius: .4em;
  background: transparent;
}
.dotsContainer button:hover {
  background: var(--input-background);
}
.edit {
  color: var(--text);
}
.delete {
  color: var(--errorRed);
}
.post p, .comment p {
  padding: 3em 1em;
  text-align: start;
  overflow-wrap: anywhere;
}
.comment p {
  padding-bottom: 1em;
}
.reply {
  right: 0;
  bottom: 0;
  margin: .5em;
  width: 5.25em;
  display: flex;
  padding: .5em;
  color: var(--gray);
  position: absolute;
  align-items: center;
  border-radius: .3em;
  background: transparent;
  justify-content: space-between;
  transition: 200ms;
}
.back:hover, .create:hover, .dots:hover, .reply:hover {
  background: rgba(113, 118, 123, 0.25);
}
@media (max-width: 500px) {
  .center form {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    position: fixed;
    border-radius: 0;
  }
  .menu {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    border-radius: 0;
  }
  .menu.active + .shadow {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
  }
  .dotsContainer {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    z-index: 4;
    width: 100vw;
    position: fixed;
    border-radius: 0;
  }
  .dotsContainer button {
    font-size: 1em;
  }
  .dotsContainer + .shadow {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
  }
}